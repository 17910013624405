import axios from 'axios'
// import { Message } from 'element-ui'

// 创建axios实例
const service = axios.create({
  baseURL: 'https://53lmc.com/', // api 的 base_url
  timeout: 20000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
 
    const res = response.data

    console.log("res1",res);
    // if (res.code < 0) {
    //   Message({
    //     message: res.message,
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    //   return Promise.reject('error')
    // } else {
      return response.data
    // }
  },
  error => {
    console.log("error1",error);
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
