// axios 发送ajax请求
import request from '@/utils/request'

export default{

  //查询商品列表
  // list() {
  //   return request({
  //     url: '/api/userController/test',
  //     method: 'get'
  //   })
  // },
  // 查询zfb
  pay (data) {
    return request({
      url: '/api/ali-pay/trade/wap/pay',
      method: 'post',
      data: data
    })
  },
  getBxmIid(bxm_id) {
    return request({
      url: '/cat?' + "bxm_id="+bxm_id,
      method: 'get'
    })
  }
}
