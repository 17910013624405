<template>
  <div v-html="html"></div>
</template>
<script>
import productApi from '../api/product1'
export default {
  data() {
    return {
      html: ''
    }
  },
  methods: {
    AliPay() {
      let param = {
        phone: this.$route.query.phone,
        bxmId: this.$route.query.bxmId
      };
      param.bxmId = param.bxmId.substring(0,32)
      productApi.pay({
        "phone": param.phone,
        "bxm_id": param.bxmId

      }).then(res => {
        console.log("进来",res);
        document.querySelector('body').innerHTML = res.data.formStr;
        this.$nextTick(() => {
          window.document.forms[0].submit();
        })

        // const div = document.createElement('div')
        // div.id = 'alipay'
        // div.innerHTML = res.data.formStr;
        // document.body.appendChild(div)
        // document.querySelector('#alipay').children[0].submit()
      })
    }
  },
  mounted() {
    this.AliPay()
  }
}
</script>
