<template>
  <div v-html="html"></div>
</template>
<script>
import productApi from "../api/product";
export default {
  data() {
    return {
      html: "",
    };
  },
  methods: {
    isPhoneNumber(phone) {
      const phoneRegex = /^1[3-9]\d{9}$/; // 中国大陆手机号的正则表达式
      return phoneRegex.test(phone);
    },
    AliPay() {
      let param = {
        phone: this.$route.query.phone,
        bxmId: this.$route.query.bxmId,
      };
      param.bxmId = param.bxmId.substring(0, 32);
      console.log(param, "参数");

      let haoma = this.isPhoneNumber(param.phone);
      console.log("ppp", haoma);
      if (haoma) {
        productApi
          .pay({
            phone: param.phone,
            bxm_id: param.bxmId,
          })
          .then((res) => {
            console.log("进来", res);

            document.querySelector("body").innerHTML = res.data.formStr;
            this.$nextTick(() => {
              window.document.forms[0].submit();
            });

            // const div = document.createElement('div')
            // div.id = 'alipay'
            // div.innerHTML = res.data.formStr;
            // document.body.appendChild(div)
            // document.querySelector('#alipay').children[0].submit()
          });
      } else {
        // *************

        const decodedString = atob(param.phone);
        console.log("datas", decodedString);
        productApi
          .pay({
            phone: decodedString,
            bxm_id: param.bxmId,
          })
          .then((res) => {
            console.log("进来1", res);

            document.querySelector("body").innerHTML = res.data.formStr;
            this.$nextTick(() => {
              window.document.forms[0].submit();
            });
          });
      }
      // *************
    },
  },
  mounted() {
    this.AliPay();
  },
};
</script>
