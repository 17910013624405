<template>
  <div class="box">
    <div style="height: 250px"></div>
    <div class="content">
      <div style="height: 20px"></div>
      <div class="input-container">
        <input
          type="text"
          v-model="phoneNumber"
          placeholder="请输入手机号"
          class="phone-input"
        />
      </div>
      <div style="height: 30px"></div>
      <div class="divider"></div>
      <div style="height: 25px"></div>
      <div class="payment-title">支付方式</div>
      <div style="height: 20px"></div>
      <div class="payment-option">
        <div style="width: 30px; height: 30px">
          <img
            src="../assets/img/Samm7X2u5bY6ibdplZ2TJ.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div style="width: 5px"></div>
        <span class="payment-text">在线支付</span>
        <div style="width: 5px"></div>
        <span class="discount">新人随机立减</span>
        <div style="flex: 1"></div>
        <div
          style="
            width: 30px;
            height: 30px;
            background-color: #0070ff;
            border-radius: 50%;
          "
        >
          <img
            class="circle-img"
            src="../assets/img/yvtvohIzlKAvPokV3OpVZ.png"
          />
        </div>
      </div>
      <div style="height: 10px"></div>
      <div class="amount-info">
        <div>
          实际支付金额: <span class="highlight">100</span>元
          <span class="original-price">原价200元</span>
        </div>
      </div>
      <div class="amount-info">
        <div>实际到账总话费: <span class="highlight">200</span>元</div>
      </div>
      <div style="height: 15px"></div>
      <div class="image-container" @click="openModal">
        <img
          id="scaling-image"
          class="imageStyle"
          :style="imageStyle"
          src="../assets/img/sx77tiwh29_1721896246.webp"
          alt=""
        />
      </div>
    </div>
    <div style="height: 1220px"></div>
    <div class="gift-description">话费礼包说明</div>
    <div
      class="description-item"
      v-for="(item, index) in descriptionItems"
      :key="index"
    >
      <span v-if="index !== 4">{{ item }}</span>
      <span v-else v-html="linkHtml"></span>
    </div>
    <div style="height: 40px"></div>
    <div class="footer">
      <div class="member-price">会员价: ¥100</div>
      <div @click="openModal" class="pay-button">
        <span :style="textStyle" class="text">立即支付</span>
      </div>
    </div>
    <div class="overlay" v-if="modalVisible">
      <div class="modal">
        <span class="close-btn" @click="closeModal">×</span>
        <div class="modal-content">恭喜,支付成功!</div>
        <div @click="closeModal" class="confirm-btn">确 定</div>
      </div>
    </div>
  </div>
</template>

<script>
import productApi from "../api/product";
// import { Message } from "element-ui";

export default {
  data() {
    return {
      modalVisible: false,
      isScaledUp: false,
      isScaledUp1: false,
      descriptionItems: [
        "1.费用说明:一次办理，有效期为十个月，激活会员后，我们将不会再收取任何费用。",
        "2.活动说明:",
        "2.1 支付 100 元参与话费月月领活动，可获得200元话费，用户可通过一番权益网站手动领取话费，每月可以手动领取20元话费，当月不领则当月话费权益作废。",
        "2.2 充值失败会自动退款，活动真实有效，如不参与可以通过联系客服申请退款!",
        "3.领取方法: 通过官方网站<br><a href='https://www.53lmc.com/#/second' target='_blank'>点击这里</a>",
        "领取话费（可复制到浏览器打开），话费领取时间段以自然月计算，比如4号充值，当月可以领取第一次，次月1号开始可领取第二次，以此类推。。",
        "4.我们为您精心挑选的电话资费充值福利，只为帮您以最低的成本享受更高的资费使用权益。已购买并领取权益的用户订单不支持退货退款，请确认后再下单。",
      ],
      linkHtml:
        "3.领取方法: 通过官方网站<br><a href='https://www.53lmc.com/#/second' target='_blank'>https://www.53lmc.com/#/second</a>",
      phoneNumber: "",
      bxmId: "",
    };
  },
  mounted() {
    //获取url参数
    this.getParams();
    this.startImageScaling(); // 组件挂载后开始缩放
    this.startTextScaling(); // 组件挂载后开始缩放
    this.panduan();
  },
  beforeDestroy() {
    this.stopImageScaling(); // 组件挂载后开始缩放
    this.stopTextScaling(); // 组件销毁前清理定时器
  },
  computed: {
    imageStyle() {
      return {
        transition: "transform 0.5s ease-in-out",
        transform: this.isScaledUp ? "scale(0.95)" : "scale(1.0)",
      };
    },
    textStyle() {
      return {
        transform: this.isScaledUp1 ? "scale(0.9)" : "scale(1.1)",
        transition: "transform 0.2s ease", // 添加过渡效果
      };
    },
  },
  methods: {
    panduan() {
      var href = window.location.href;
      var queryString = href.split("?")[1] || "";
      let params = this.parseQueryString(queryString);
      console.log("参数啊哈哈哈", params);
      let cc = this.isPhoneNumber(params.phone);
      if (cc) {
        this.phoneNumber = params;
      } else {
        const decodedString = atob(params.phone);
        console.log("datas", decodedString);
        this.phoneNumber = decodedString;
      }
    },
    isPhoneNumber(phone) {
      const phoneRegex = /^1[3-9]\d{9}$/; // 中国大陆手机号的正则表达式
      return phoneRegex.test(phone);
    },
    getParams() {
      // 获取完整的查询字符串部分，包括“?”符号
      var href = window.location.href;
      var queryString = href.split("?")[1] || "";
      let params = this.parseQueryString(queryString);
      // 获取 bxm_id 参数的值
      this.bxmId = params.bxm_id ? params.bxm_id : 0;
      this.getBxmIid();
    },
    getBxmIid() {
      productApi.getBxmIid(this.bxmId).then((response) => {
        console.log("查询订单状态：" + response.code);
      });
    },
    // 解析查询字符串为对象
    parseQueryString(queryString) {
      let params = {};
      let pairs = queryString.split("&");
      pairs.forEach((pair) => {
        let [key, value] = pair.split("=");
        params[decodeURIComponent(key)] = decodeURIComponent(value || "");
      });
      return params;
    },
    openModal() {
      if (new RegExp(/^1[3|4|5|7|8|9][0-9]\d{8}$/).test(this.phoneNumber)) {
        // 支付宝支付
        this.$router.push({
          path: "/zfb",
          query: { phone: this.phoneNumber, bxmId: this.bxmId },
        });
      } else {
        Message({
          message: "手机号码格式错误!",
          type: "error",
          duration: 2 * 1000,
        });
      }
    },

    getOrderDetail() {
      // 这里是轮询的逻辑
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let initTime = +new Date();
        let loop = () => {
          productApi
            .getOrderDetail({ orderCode: this.orderCode })
            .then((res) => {
              if (res.code === 20000 && res.data && res.data.payStatus === 30) {
                this.showSuccessModal(); // 支付成功
              } else {
                let now = +new Date();
                if (now - initTime < 45000) {
                  loop(); // 继续轮询
                } else {
                  console.log("支付超时");
                  clearTimeout(this.timer);
                }
              }
            });
        };
        loop();
      }, 500);
    },

    showSuccessModal() {
      this.modalVisible = true; // 显示支付成功的模态框
    },

    closeModal() {
      this.modalVisible = false;
      this.stopTextScaling(); // 确保在关闭模态框时清理定时器
    },
    startImageScaling() {
      // console.log("开始缩放");
      this.scaleInterval = setInterval(() => {
        this.isScaledUp = !this.isScaledUp;
        // console.log("当前状态: ", this.isScaledUp);
      }, 500);
    },
    stopImageScaling() {
      clearInterval(this.scaleInterval);
      this.isScaledUp = false;
      // console.log("停止缩放");
    },

    startTextScaling() {
      // console.log("开始缩放");
      this.scaleInterval = setInterval(() => {
        this.isScaledUp1 = !this.isScaledUp1;
        // console.log("当前状态: ", this.isScaledUp1);
      }, 500);
    },
    stopTextScaling() {
      clearInterval(this.scaleInterval);
      this.isScaledUp1 = false;
      // console.log("停止缩放");
    },
    tis() {
      this.$router.push("/second");
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

.box {
  width: 100%;
  height: 2500px;
  background-image: url(../assets/img/3j0mwb0ozg_1721790653.webp);
  background-size: 100% 100%;
  /* 注意这里的连字符 */
  background-repeat: no-repeat;
  /* 同样注意连字符 */
}

input:focus {
  outline: none;
}

.content {
  height: 400px;
  width: 95%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
}

.input-container {
  width: 95%;
  margin: 0 auto;
  height: 50px;
  border-radius: 20px;
  background-color: #f5f5f5;
}

.phone-input {
  font-size: 20px;
  height: 100%;
  width: 100%;
  border: none;
  background-color: #f5f5f5;
  border-radius: 20px;
  font-weight: 550;
  text-indent: 20px;
  opacity: 0.8;
}

.divider {
  width: 95%;
  height: 1px;
  margin: 0 auto;
  border-bottom: 3px solid #f5f5f5;
}

.payment-title {
  width: 95%;
  margin: 0 auto;
  font-weight: 550;
  font-size: 23px;
}

.payment-option {
  width: 95%;
  display: flex;
  margin: 0 auto;
}

.payment-text {
  height: 30px;
  line-height: 30px;
  font-size: 20px;
}

.discount {
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  border: 1px solid red;
  color: red;
  width: 160px;
  text-align: center;
}

.circle-img {
  width: 50%;
  height: 50%;
  margin-left: 25%;
  margin-top: 25%;
  background-color: #0070ff;
  border-radius: 50%;
}

.amount-info {
  width: 95%;
  display: flex;
  margin: 0 auto;
  line-height: 30px;
  font-size: 20px;
}

.highlight {
  color: #0070ff;
  font-weight: 550;
}

.original-price {
  text-decoration: line-through;
  font-size: 18px;
  color: #777777;
}

.image-container {
  width: 95%;
  margin: 0 auto;
  height: 70px;
}

.footer {
  width: 100%;
  height: 70px;
  background-image: url(../assets/img/75wnuicxlwau5bjbr_pcw.webp);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 0;
  display: flex;
}

.member-price {
  width: 50%;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 25px;
  color: #ffd972;
}

.pay-button {
  width: 50%;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 25px;
  color: #ffffff;
  font-weight: 550;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* 半透明背景 */
  display: none;
  /* 默认隐藏 */
  justify-content: center;
  align-items: center;
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
}

/* 弹框样式 */
.modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 关闭按钮样式 */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
}

/* 确定按钮样式 */
.confirm-btn {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-btn:hover {
  background-color: #45a049;
}

/* 话费礼包说明 */
.gift-description {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-weight: 550;
  font-size: 23px;
}

.description-item {
  width: 80%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 25px;
}

.text {
  font-size: 24px;
  transition: transform 0.5s ease-in-out;
  display: inline-block;
}

.imageStyle {
  width: 100%;
  height: 100%;
}
</style>