<template>
  <div style="width: 100%; oberflow: hidden">
    <div>
      <van-notice-bar
        scrollable
        left-icon="volume-o"
        text="现在充话费有优惠了,可以查看下方活动,点击参与吧!"
      />
    </div>
    <div style="height: 20px"></div>
    <div
      style="
        width: 95%;
        margin: 0 auto;
        height: 50px;
        border-radius: 20px;
        display: flex;
      "
    >
      <div class="input-wrapper">
        <input
          style="
            height: 80%;
            width: 100%;
            border: none;
            outline: none;
            font-size: 28px;
            color: #333333;
          "
          type="text"
          v-model="inputValue"
          placeholder="请输入手机号"
          @input="onInput"
        />
        <span
          v-if="inputValue"
          class="clear-btn"
          style="
            border: 1px solid;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            text-align: center;
            line-height: 22px;
          "
          @click="clearInput"
          >X</span
        >
      </div>
    </div>
    <div
      style="
        width: 73%;
        margin: 0 auto;
        color: gray;
        opacity: 0.8;
        font-size: 13px;
      "
    >
      北京移动
    </div>

    <div
      style="
        width: 90%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        flex-wrap: wrap;
        border-radius: 10px;
      "
    >
      <div
        style="
          width: 30%;
          height: 50px;
          border: 1px solid;
          margin-top: 30px;
          color: gray;
          border-radius: 10px;
          font-weight: 550;
        "
        :style="{
          backgroundColor: item.isActive ? 'lightblue' : 'white',
          color: item.isActive ? 'white' : 'black',
        }"
        @click="toggleColor(index)"
        class="box"
        v-for="(item, index) in arr"
        :key="index"
      >
        <div style="height: 5px"></div>
        <div style="width: 100%; text-align: center; font-size: 18px">
          {{ item.biao }}
        </div>
        <div style="width: 100%; text-align: center; font-size: 12px">
          {{ item.shou }}
        </div>
      </div>
    </div>
    <!-- <div
      style="
        width: 90%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        flex-wrap: wrap;
        border-radius: 10px;
      "
    >
      <div
        style="
          width: 30%;
          height: 50px;
          border: 1px solid;
          margin-top: 30px;
          color: gray;
          border-radius: 10px;
        "
      >
        <div style="height: 5px"></div>
        <div style="width: 100%; text-align: center; font-size: 18px">
          100.00元
        </div>
        <div style="width: 100%; text-align: center; font-size: 12px">
          售价:80.00元
        </div>
      </div>
      <div
        style="
          width: 30%;
          height: 50px;
          border: 1px solid;
          margin-top: 30px;
          color: gray;
          border-radius: 10px;
          background: #fff8e8;
        "
      >
        <div style="height: 5px"></div>
        <div style="width: 100%; text-align: center; font-size: 18px">
          充流量
        </div>
        <div style="width: 100%; text-align: center; font-size: 12px">
          流量补给
        </div>
      </div>
      <div
        style="
          width: 30%;
          height: 50px;
          border: 1px solid;
          margin-top: 30px;
          color: gray;
          border-radius: 10px;
          background: #fff8e8;
        "
      >
        <div style="height: 5px"></div>
        <div style="width: 100%; text-align: center; font-size: 18px">
          0元30G
        </div>
        <div style="width: 100%; text-align: center; font-size: 12px">
          0元30G
        </div>
      </div>
    </div> -->
    <div style="height: 50px"></div>
    <div
      style="
        width: 90%;
        margin: 0 auto;
        background: #f00c01;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: white;
        border-radius: 30px;
      "
      @click="openModal"
    >
      立即支付:80.00元
    </div>
    <div
      style="
        width: 80%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        flex-wrap: wrap;
        border-radius: 10px;
      "
    >
      <div
        style="
          width: 48%;
          height: 30px;
          margin-top: 10px;
          color: gray;
          border-radius: 10px;
        "
      >
        <div style="height: 5px"></div>
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 18px;
            display: flex;
          "
        >
          <div style="width: 20px"></div>
          <div>
            <img
              src="../assets/温馨提示.png"
              style="width: 20px; height: 20px"
              alt=""
            />
          </div>
          <div>温馨提示</div>
        </div>
      </div>
      <div
        style="
          width: 48%;
          height: 30px;
          margin-top: 10px;
          color: gray;
          border-radius: 10px;
        "
      >
        <div style="height: 5px"></div>
        <div
          style="
            width: 100%;
            text-align: center;
            font-size: 18px;
            display: flex;
          "
        >
          <div style="width: 20px"></div>
          <div>
            <img
              src="../assets/充值记录.png"
              style="width: 20px; height: 20px"
              alt=""
            />
          </div>

          <div>充值记录</div>
        </div>
      </div>
    </div>
    <div style="height: 20px"></div>

    <div style="width: 90%; margin: 0 auto; background: #f6f6f6; height: 400px">
      <div style="height: 10px"></div>
      <div style="width: 90%; display: flex; margin: 0 auto">
        <div>
          <img src="../assets/分期-火.png" style="height: 20px; width: 20px" />
        </div>
        <div>精选爆款</div>
      </div>
      <div style="width: 100%; display: flex; flex-wrap: wrap">
        <div
          style="
            width: 95%;
            background: white;
            margin: 0 auto;
            border-radius: 10px;
            margin-top: 10px;
          "
          v-for="(item, index) in dat"
          :key="index"
        >
          <div style="height: 10px"></div>

          <div style="width: 100%; display: flex">
            <div style="width: 10px"></div>
            <div style="width: 60px; height: 60px">
              <img src="../assets/a.jpg" style="width: 100%; height: 100%" />
            </div>
            <div style="width: 10px"></div>
            <div>
              <div style="height: 10px"></div>
              <div style="font-weight: 550">{{ item.title }}</div>
              <div style="color: #e55b57">{{ item.time }}</div>
            </div>
            <div style="flex: 1"></div>
            <div>
              <div style="height: 1px"></div>
              <div style="font-size: 25px; color: #ff4633">
                {{ item.monney }}
              </div>
              <div style="color: #868686">{{ item.reduce }}</div>
            </div>
            <div style="width: 10px"></div>
          </div>
          <div style="height: 10px"></div>
        </div>
        <!-- <div
          style="
            width: 45%;
            background: white;
            margin-left: 10px;
            margin-top: 10px;
          "
        >
          <div style="height: 10px"></div>
          <div style="width: 100%; text-align: center">首月1元15G</div>
          <div style="height: 5px"></div>
          <div
            style="
              width: 100%;
              text-align: center;
              font-size: 15px;
              color: gray;
            "
          >
            送视频会员月卡
          </div>
          <div style="height: 10px"></div>
          <div
            style="
              width: 80%;
              text-align: center;
              height: 25px;
              line-height: 25px;
              margin: 0 auto;
              background: red;
              color: white;
              border-radius: 30px;
            "
          >
            去查看>
          </div>
          <div style="height: 10px"></div>
        </div> -->
        <!-- <div style="width: 45%; background: white">
          <div style="height: 10px"></div>
          <div style="width: 100%; text-align: center">爆款精选</div>
          <div style="height: 5px"></div>
          <div
            style="
              width: 100%;
              text-align: center;
              font-size: 15px;
              color: gray;
            "
          >
            为您推荐
          </div>
          <div style="height: 10px"></div>
          <div
            style="
              width: 80%;
              text-align: center;
              height: 25px;
              line-height: 25px;
              margin: 0 auto;
              background: red;
              color: white;
              border-radius: 30px;
            "
          >
            去查看>
          </div>
          <div style="height: 10px"></div>
        </div>
        <div style="width: 45%; background: white; margin-top: 15px">
          <div style="height: 10px"></div>
          <div style="width: 100%; text-align: center">2GB日包</div>
          <div style="height: 5px"></div>
          <div
            style="
              width: 100%;
              text-align: center;
              font-size: 15px;
              color: gray;
            "
          >
            赠会员、美团红包等
          </div>
          <div style="height: 10px"></div>
          <div
            style="
              width: 80%;
              text-align: center;
              height: 25px;
              line-height: 25px;
              margin: 0 auto;
              background: red;
              color: white;
              border-radius: 30px;
            "
          >
            去查看>
          </div>
          <div style="height: 10px"></div>
        </div>
        <div style="width: 45%; background: white; margin-top: 15px">
          <div style="height: 10px"></div>
          <div style="width: 100%; text-align: center">权益专区</div>
          <div style="height: 5px"></div>
          <div
            style="
              width: 100%;
              text-align: center;
              font-size: 15px;
              color: gray;
            "
          >
            流量+视频会员等
          </div>
          <div style="height: 10px"></div>
          <div
            style="
              width: 80%;
              text-align: center;
              height: 25px;
              line-height: 25px;
              margin: 0 auto;
              background: red;
              color: white;
              border-radius: 30px;
            "
          >
            去查看>
          </div>
          <div style="height: 10px"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
export default {
  data() {
    return {
      arr: [
        { biao: "100.00元", shou: "售价:80.00元", isActive: false },
        { biao: "100.00元", shou: "售价:80.00元", isActive: false },
        { biao: "100.00元", shou: "售价:80.00元", isActive: false },
        { biao: "100.00元", shou: "售价:80.00元", isActive: false },
        { biao: "充流量", shou: "流量补给", isActive: false },
        { biao: "0元30G", shou: "0元30G", isActive: false },
      ],
      arr1: [{}, {}],
      inputValue: "",
      dat: [
        {
          title: "爆红包",
          time: "已到期",
          monney: "￥20",
          reduce: "满100可用",
        },
      ],
      bxmId: "",
    };
  },
  methods: {
    toggleColor(index) {
      console.log(index);
      if (index == 4 || index == 5) {
        Message({
          message: "已磐售!",
          type: "warming",
          duration: 2 * 1000,
        });
        return;
      }
      // 切换当前项的 isActive 状态
      this.arr.forEach((item) => (item.isActive = false));
      // 将被点击的项的 isActive 状态设置为 true
      this.arr[index].isActive = true;
    },
    openModal() {
      if (new RegExp(/^1[3|4|5|7|8|9][0-9]\d{8}$/).test(this.inputValue)) {
        // 支付宝支付
        this.$router.push({
          path: "/zfb1",
          query: { phone: this.inputValue, bxmId: this.bxmId },
        });
      } else {
        Message({
          message: "手机号码格式错误!",
          type: "error",
          duration: 2 * 1000,
        });
      }
    },
    onInput(event) {
      this.inputValue = event.target.value;
    },
    clearInput() {
      this.inputValue = "";
    },
  },
};
</script>

<style>
.input-wrapper {
  width: 80%;
  margin: 0 auto;
  position: relative;
  display: inline-block;
}

.clear-btn {
  position: absolute;
  right: 5px;
  top: 42%;
  transform: translateY(-50%);
  cursor: pointer;
  color: gray;
}
</style>